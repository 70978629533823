<template>
  <div class="position-relative">
    <section ref="allImages" class="allImages">
      <b-row class="flex-lg-row flex-column-reverse">
        <b-col v-if="images.length > 1" lg="3" class="px-4">
          <div class="swiper-container gallery-thumbs">
            <div class="swiper-button-next d-flex justify-content-center align-items-center">
              <i class="las la-angle-right"></i>
            </div>
            <div class="swiper-button-prev d-flex justify-content-center align-items-center">
              <i class="las la-angle-left"></i>
            </div>
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(image, key) in images"
                :key="key"
              >
                <div
                  class="iq-border-radius-10 swiper-slide-container image_slider_background"
                  :style="{
                  'background-size': 'cover',
                    'background-image':
                      'url(' + image.image + ')',
                  }"
                >
<!--                                    <img-->
<!--                      :alt="image.name"-->
<!--                      :src="'https://curvaegypt.com/'+image.image"-->
<!--                      class="img-fluid w-100 h-100 mx-auto iq-border-radius-5"-->
<!--                  />-->
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col :lg="images.length > 1 ? 9: 12">
          <div class="swiper-container gallery-top mb-3 w-100">
            <div class="swiper-wrapper w-100">
              <div
                class="swiper-slide w-100"
                v-for="(image, key) in images"
                :key="key"
              >
                <div class="swiper-slide-container w-100">
                  <img
                    :src="image.image"
                    class="img-fluid w-100 mx-auto iq-border-radius-5"
                    :alt="image.name"
                    @error="replaceByDefault"
                  />
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </section>
    <section
      class="loading-images position-absolute w-100 h-170px bg-white top-0 left-0"
      v-if="loading"
    >
      <spinner-loading text="loading Images" />
    </section>
  </div>
</template>
<script>
import Swiper, {
  Navigation,
  Pagination,
  Parallax,
  Autoplay,
  Thumbs,
  Controller
} from 'swiper'
Swiper.use([Navigation, Pagination, Parallax, Autoplay, Thumbs, Controller])
let galleryTop, galleryThumbs
export default {
  props: {
    images: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  methods: {
    init () {
      galleryTop = new Swiper('.gallery-top', {
        spaceBetween: 10,
        loop: false,
        slidesPerView: 1,
        centeredSlides: true
      })
      galleryThumbs = new Swiper('.gallery-thumbs', {
        spaceBetween: 15,
        centeredSlides: true,
        slidesPerView: 1,
        slideToClickedSlide: true,
        loop: false,
        loopedSlides: 1
      })
      galleryTop.controller.control = galleryThumbs
      galleryThumbs.controller.control = galleryTop
    },
    replaceByDefault (e) {
      e.target.src = this.defaultImage
    }
  },
  data () {
    return {
      defaultImage: require('@/assets/images/curva/product.png'),
      loading: true
    }
  },
  mounted () {
    this.loading = true
    setTimeout(() => {
      galleryTop = new Swiper('.gallery-top', {
        spaceBetween: 10,
        loop: false,
        slidesPerView: 1,
        initial: 1,
        centeredSlides: true
      })
      galleryThumbs = new Swiper('.gallery-thumbs', {
        direction: 'vertical',
        spaceBetween: 7,
        centeredSlides: true,
        slidesPerView: 3.3,
        breakpoints: {
          0: {
            direction: 'horizontal'
            // slidesPerView: 1,
            // spaceBetween: 15
          },
          850: {
            direction: 'vertical'
          }
        },
        slideToClickedSlide: true,
        loop: false,
        initial: 1,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      })
      galleryTop.controller.control = galleryThumbs
      galleryThumbs.controller.control = galleryTop
      this.loading = false
    }, 100)
  }
}
</script>
<style>
.gallery-thumbs {
  height: 300px;
}
.swiper-button-prev {
  position: absolute;
  top: 0;
  background: rgb(113 105 105 / 48%);
  width: 100%;
  text-align: center;
  z-index: 2;
  color: #e6dede;
  font-weight: bold;
  cursor: pointer;
}
.swiper-button-next {
  position: absolute;
  bottom: 0;
  background: rgb(113 105 105 / 48%);
  width: 100%;
  text-align: center;
  z-index: 2;
  color: var(--iq-warning);
  font-weight: bold;
  cursor: pointer;
}
.image_slider_background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media only screen and (max-width: 850px) {
  .gallery-thumbs .swiper-slide-active::after {
    transform: rotate(-90deg);
    top: -15px;
    left: 47%;
  }
  .swiper-button-prev {
    top: 0;
    left: 0;
    height: 100%;
    width: 20px;
  }
  .swiper-button-next {
    top: 0;
    right: 0;
    height: 100%;
    width: 20px;
  }
  .gallery-thumbs {
    height: 110px;
    padding: 10px;
  }
}
</style>
