// Layout
const curvaLayout = () => import('@/layouts/curvaLayout')

const terms = () => import('./views/terms')

// start routes
export default [
  {
    path: '/terms',
    name: 'terms',
    component: curvaLayout,
    meta: { name: 'terms', layout: 'nav-with-menu' },
    children: [{
      path: '',
      name: 'terms',
      component: terms,
      meta: {
        name: 'terms',
        layout: 'nav-with-menu',
        breadCrumb: [
          {
            text: 'terms.title',
            active: true
          }
        ]
      }
    }]
  }
]
