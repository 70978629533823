// Layout
const curvaLayout = () => import('@/layouts/curvaLayout')

const main = () => import('./views/main')

// start routes
export default [
  {
    path: '/',
    name: 'index',
    component: curvaLayout,
    meta: { name: 'index', layout: 'nav-with-menu' },
    children: [{
      path: '',
      name: 'index',
      component: main,
      meta: { name: 'index', layout: 'nav-with-menu' }
    }]
  }
]
