// Layout
const curvaLayout = () => import('@/layouts/curvaLayout')

const list = () => import('./views/list')
const profileInfo = () => import('./views/profileInfo')
const addressBook = () => import('./views/addressBook')
const myOrders = () => import('./views/myOrders')
const wishlist = () => import('./views/wishlist')

const editProfile = () => import('./components/editProfile')
const changePassword = () => import('./components/changePassword')
const addAddress = () => import('./components/addAddress')
const orderDetails = () => import('./components/orderDetails')

// start routes
export default [
  {
    path: '/profile',
    name: 'profileInfo',
    component: curvaLayout,
    meta: { name: 'profile', layout: 'nav-with-menu' },
    children: [{
      path: '',
      name: 'profileInfo',
      component: list,
      meta: { name: 'profile', layout: 'nav-with-menu' },
      children: [{
        path: '',
        name: 'profileInfo',
        component: profileInfo,
        meta: {
          name: 'profileInfo',
          layout: 'nav-with-menu',
          breadCrumb: [
            {
              text: 'profile.profile',
              active: true
            }
          ]
        }
      },
      {
        path: 'profileInfo/editProfile',
        name: 'editProfile',
        component: editProfile,
        meta: {
          name: 'editProfile',
          layout: 'nav-with-menu',
          breadCrumb: [
            {
              text: 'profile.profile',
              to: 'profileInfo'
            },
            {
              text: 'profile.editProfile',
              active: true
            }
          ]
        }
      },
      {
        path: 'profileInfo/changePassword',
        name: 'changePassword',
        component: changePassword,
        meta: {
          name: 'changePassword',
          layout: 'nav-with-menu',
          breadCrumb: [
            {
              text: 'profile.profile',
              to: 'profileInfo'
            },
            {
              text: 'profile.changePass',
              active: true
            }
          ]
        }
      },
      {
        path: 'addressBook',
        name: 'addressBook',
        component: addressBook,
        meta: {
          name: 'addressBook',
          layout: 'nav-with-menu',
          breadCrumb: [
            {
              text: 'profile.profile',
              to: 'profileInfo'
            },
            {
              text: 'profile.address',
              active: true
            }
          ]
        }
      },
      {
        path: 'addressBook/addAddress',
        name: 'addAddress',
        component: addAddress,
        meta: {
          name: 'addAddress',
          layout: 'nav-with-menu',
          breadCrumb: [
            {
              text: 'profile.profile',
              to: 'profileInfo'
            },
            {
              text: 'profile.address',
              to: 'addressBook'
            },
            {
              text: 'profile.addAddress',
              active: true
            }
          ]
        }
      },
      {
        path: 'orders',
        name: 'myOrders',
        component: myOrders,
        meta: {
          name: 'myOrders',
          layout: 'nav-with-menu',
          breadCrumb: [
            {
              text: 'profile.profile',
              to: 'profileInfo'
            },
            {
              text: 'profile.orders',
              active: true
            }
          ]
        }
      },
      {
        path: 'orders/orderDetails/:id',
        name: 'orderDetails',
        component: orderDetails,
        meta: {
          name: 'orderDetails',
          layout: 'nav-with-menu',
          breadCrumb: [
            {
              text: 'profile.profile',
              to: 'profileInfo'
            },
            {
              text: 'profile.orders',
              to: 'myOrders'
            },
            {
              text: 'profile.orderDetails',
              active: true
            }
          ]
        }
      },
      {
        path: 'wishlist',
        name: 'wishlist',
        component: wishlist,
        meta: {
          name: 'wishlist',
          layout: 'nav-with-menu',
          breadCrumb: [
            {
              text: 'profile.profile',
              to: 'profileInfo'
            },
            {
              text: 'profile.wishlist',
              active: true
            }
          ]
        }
      }]
    }]
  }
]
