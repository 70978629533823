// Layout
const curvaLayout = () => import('@/layouts/curvaLayout')

const aboutCurva = () => import('./views/about-curva')
const topBrands = () => import('./views/top-brands')
const topClubs = () => import('./views/top-clubs')
const topProducts = () => import('./views/top-products')
const arrivals = () => import('./views/new-arrival')
const clubDetails = () => import('./views/club-details')
const brandDetails = () => import('./views/brand-details')

// start routes
export default [
  {
    path: '/about',
    name: 'about',
    component: curvaLayout,
    meta: { name: 'about', layout: 'nav-with-menu' },
    children: [
      {
        path: '',
        name: 'about',
        component: aboutCurva,
        meta: {
          name: 'about',
          layout: 'nav-with-menu',
          breadCrumb: [
            {
              text: 'mainNav.about',
              active: true
            }
          ]
        }
      },
      {
        path: 'top-brands',
        name: 'topBrands',
        component: topBrands,
        meta: {
          name: 'topBrands',
          layout: 'nav-with-menu',
          breadCrumb: [
            {
              text: 'mainNav.stats'
            },
            {
              text: 'mainNav.brands',
              active: true
            }
          ]
        }
      },
      {
        path: 'new-arrivals',
        name: 'newArrivals',
        component: arrivals,
        meta: {
          name: 'newArrivals',
          layout: 'nav-with-menu',
          breadCrumb: [
            {
              text: 'mainNav.stats'
            },
            {
              text: 'main.arrivals',
              active: true
            }
          ]
        }
      },
      {
        path: 'top-clubs',
        name: 'topClubs',
        component: topClubs,
        meta: {
          name: 'topClubs',
          layout: 'nav-with-menu',
          breadCrumb: [
            {
              text: 'mainNav.stats'
            },
            {
              text: 'mainNav.clubs',
              active: true
            }
          ]
        }
      },
      {
        path: 'club-details/:id',
        name: 'clubDetails',
        component: clubDetails,
        meta: {
          name: 'clubDetails',
          layout: 'nav-with-menu',
          breadCrumb: [
            {
              text: 'mainNav.stats'
            },
            {
              text: 'mainNav.clubs',
              to: 'topClubs'
            },
            {
              text: 'main.clubDetails',
              active: true
            }
          ]
        }
      },
      {
        path: 'top-products',
        name: 'topProducts',
        component: topProducts,
        meta: {
          name: 'topProducts',
          layout: 'nav-with-menu',
          breadCrumb: [
            {
              text: 'mainNav.stats'
            },
            {
              text: 'mainNav.products',
              active: true
            }
          ]
        }
      },
      {
        path: 'brand-details/:id',
        name: 'brandDetails',
        component: brandDetails,
        meta: {
          name: 'brandDetails',
          layout: 'nav-with-menu',
          breadCrumb: [
            {
              text: 'mainNav.stats'
            },
            {
              text: 'mainNav.brands',
              to: 'topBrands'
            },
            {
              text: 'productDetails.brand',
              active: true
            }
          ]
        }
      }
    ]
  }
]
