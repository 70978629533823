// Layout
const curvaLayout = () => import('@/layouts/curvaLayout')

const login = () => import('./views/login')
const registration = () => import('./views/registration')
const authentication = () => import('./views/authentication')
const forget = () => import('./views/forgetPass')
const reset = () => import('./views/resetPass')

// start routes
export default [
  {
    path: '/login',
    name: 'login',
    component: curvaLayout,
    meta: { name: 'login', layout: 'nav-with-menu' },
    children: [{
      path: '',
      name: 'login',
      component: login,
      meta: {
        name: 'login',
        layout: 'nav-with-menu',
        breadCrumb: [
          {
            text: 'login.login',
            active: true
          }
        ]
      }
    }]
  },
  {
    path: '/forgotPassword',
    name: 'forget',
    component: curvaLayout,
    meta: { name: 'forget', layout: 'nav-with-menu' },
    children: [{
      path: '',
      name: 'forget',
      component: forget,
      meta: {
        name: 'forget',
        layout: 'nav-with-menu',
        breadCrumb: [
          {
            text: 'forgot.bread',
            active: true
          }
        ]
      }
    }]
  },
  {
    path: '/reset-password',
    name: 'reset',
    component: curvaLayout,
    meta: { name: 'reset', layout: 'nav-with-menu' },
    children: [{
      path: '',
      name: 'reset',
      component: reset,
      meta: {
        name: 'reset',
        layout: 'nav-with-menu',
        breadCrumb: [
          {
            text: 'reset.reset',
            active: true
          }
        ]
      }
    }]
  },
  {
    path: '/registration',
    name: 'registration',
    component: curvaLayout,
    meta: { name: 'registration', layout: 'nav-with-menu' },
    children: [{
      path: '',
      name: 'registration',
      component: registration,
      meta: {
        name: 'registration',
        layout: 'nav-with-menu',
        breadCrumb: [
          {
            text: 'register.register',
            active: true
          }
        ]
      }
    }]
  },
  {
    path: '/authentication',
    name: 'authentication',
    component: authentication,
    meta: { name: 'authentication', layout: 'nav-with-menu' }
  }
]
