import firebase from 'firebase'

const firebaseConfig = {
  apiKey: 'AIzaSyC-t0X7kjpwpCsuS-VUQm0tCjb5bUeGl0w',
  authDomain: 'nemken-9ef56.firebaseapp.com',
  projectId: 'nemken-9ef56',
  storageBucket: 'nemken-9ef56.appspot.com',
  messagingSenderId: '991558651084',
  appId: '1:991558651084:web:b2f92d84e77dcf0d148669',
  measurementId: 'G-P243276M75'
}
firebase.initializeApp(firebaseConfig)
