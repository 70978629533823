// Layout
const curvaLayout = () => import('@/layouts/curvaLayout')

const social = () => import('./views/social')

// start routes
export default [
  {
    path: '/social',
    name: 'social',
    component: curvaLayout,
    meta: { name: 'social', layout: 'nav-with-menu' },
    children: [{
      path: '',
      name: 'social',
      component: social,
      meta: {
        name: 'social',
        layout: 'nav-with-menu',
        breadCrumb: [
          {
            text: 'mainNav.social',
            active: true
          }
        ]
      }
    }]
  }
]
