<template>
  <router-link :to="{name: 'productDetails', params: {id: product.id}}">
  <b-card
      class="product-card-container mb-5 cursor-pointer"
  >
    <!-- :style="{ 'background-image': 'url('+ 'https://curvaegypt.com/'+product.default_image.image + ')' }" -->
    <div class="img-container d-flex justify-content-center align-content-center">
      <img class="" :src="product.image"
           @error="replaceByDefault"/></div>
    <b-card-body class="p-3 text-initial">
      <h4 style="white-space: nowrap; overflow: hidden;">
        {{ product.name.length > 30? product.name.substring(0,30)+'...': product.name}}</h4>
      <div class="d-flex justify-content-between align-items-start">
        <p v-if="product.offer > 0" class="font-size-18"><span class="font-weight-bold text-warning">
          {{ Math.floor(product.price - (Math.floor(product.offer * product.price )/100))}} </span>
          <span class="font-size-12"> {{$t('egp')}}</span></p>
        <p v-if="product.offer > 0" class="text-decoration-line-through font-size-18 font-weight-bold text-secondary">
          {{ product.price }} <span class="font-size-12">{{$t('egp')}}</span></p>
        <p v-else class="font-size-18">
          <span class="font-weight-bold text-warning"
          >{{ product.price }}
          </span>
          <span class="font-size-12"> {{$t('egp')}}</span>
        </p>
        <span class="d-inline-flex product-card-icons-container flex-sm-row flex-column-reverse">
          <i class="las la-shopping-cart font-size-30"> </i>
          <i
              class="flip-dir las la-long-arrow-alt-right card-arrow-icon font-size-30"
          ></i>
        </span>
      </div>
    </b-card-body>
    <button class="fav-btn" @click.stop.prevent="addFav">
      <i class=" font-size-18" :class="product.in_wishlist?'fas fa-heart fav-color' : 'far fa-heart'"></i>
    </button>
    <div class="tags">
      <span v-if="product.offer > 0" class="tag offer-tag"> {{ $t('main.offer') }} </span>
<!--      <span v-if="product.avaiability === 'available'" class="tag arrival-tag">
        {{ $t('main.available') }}
      </span>-->
      <span v-if="product.avaiability === 'Soon'" class="tag soon-tag">
        {{ $t('main.soon') }}
      </span>
    </div>
  </b-card>
  </router-link>
</template>

<script>
import ProfileServices from '@/modules/profile/services/profile.services'
export default {
  props: {
    product: {
      type: Object
    }
  },
  data () {
    return {
      defaultImage: require('@/assets/images/curva/product.png')
    }
  },
  methods: {
    addFav () {
      console.log(this.product)
      if (!this.product.in_wishlist) {
        ProfileServices.addToWishlist(this.product.id).then(res => {
          this.product.in_wishlist = res.data.data.product.in_wishlist
        })
      } else {
        ProfileServices.removeFromWishlist(this.product.in_wishlist).then(res => {
          this.product.in_wishlist = false
        })
      }
    },
    replaceByDefault (e) {
      e.target.src = this.defaultImage
    }
  }
}
</script>
<style>
.product-card-container {
  border-radius: 15px !important;
  overflow: hidden !important;
}
.product-card-container .card-body {
  padding: 0px !important;
}
.img-container {
  width: 100%;
  height: 300px;
}
.img-container {
  width: auto;
  background-size: cover;
  /*filter: blur(4px);*/
}

.fav-color {
  color: var(--iq-blush);
}
.tags {
  position: absolute;
  top: 10px;
  left: 15px;
}
.text-decoration-line-through {
  text-decoration: line-through;
}
</style>
